import React from "react";
import {Suspense,useState} from "react";
import ReactDOM from 'react-dom/client'; 
import { BrowserRouter,HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from '@tanstack/react-query';
import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'; 
import { Provider } from 'jotai'; 
import { SnackbarProvider } from 'notistack'; 
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask'
import { WalletConnect } from '@web3-react/walletconnect';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';  
//import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// theme
import ThemeProvider from './theme';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import {
  metaMask,
  metaMaskHooks,
  walletConnect,
  walletConnectHooks,
  coinbaseWallet,
  coinbaseWalletHooks,
} from './utils/connectors';
import AppIntlProvider from './components/AppIntlProvider';
import { Updater } from './components/transactions/Updater';  
import 'react-notifications/lib/notifications.css'; 
import { SettingsProvider } from './contexts/SettingsContext'; 
// import { AccountContextProvider } from './contexts/AccountContext';
// import { MarketplaceContextProvider } from './contexts/MarketplaceContext'; 
import createEmotionCache from './createEmotionCache';   

const connectors: [MetaMask | WalletConnect | CoinbaseWallet, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks],
]
 
const queryClient =   new QueryClient({
    defaultOptions: {
      queries: {
        suspense: false,
      },
    },
  }) 
interface Props {
  children: React.ReactNode;
}

const AppProvider = ({ children }:Props) => {
  return (
 <Web3ReactProvider connectors={connectors}>
   <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <SettingsProvider> 
          {/* <MarketplaceContextProvider>
            <AccountContextProvider> */}
             <Provider> 
              <SnackbarProvider maxSnack={3}>
                <AppIntlProvider>  
                  <LocalizationProvider dateAdapter={AdapterMoment}> 
                  <ThemeProvider>
                    <NotistackProvider>
                      <ProgressBarStyle /> 
                      <Updater />
                       <CssBaseline />
                      { children } 
                     </NotistackProvider>
                    <ToastContainer  />
                  </ThemeProvider>
                  </LocalizationProvider>
                 </AppIntlProvider> 
                </SnackbarProvider>
                </Provider>
              {/* </AccountContextProvider>
            </MarketplaceContextProvider>  */}
        </SettingsProvider>
      </QueryClientProvider>
     </HelmetProvider>
  </Web3ReactProvider>
  );
}

export default AppProvider;
