
// @mui
import { styled,  } from '@mui/material/styles';
import { Grid,  Typography, Stack, Box, } from '@mui/material';
import useSettings from "../../hooks/useSettings"; 
// components
import RouterLink from './../../components/RouterLink';
import { GradientStyle } from '../../components/StyledComponents';
import { purplePreset } from './../../utils/getColorPresets';
import IconfyButton  from './../../components/IconfyButton';
// ----------------------------------------------------------------------


const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    paddingBottom: 10,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
    const { themeMode, onToggleMode } = useSettings();   
    return (
        <RootStyle>
{/*             <Box sx={{ pt: 4, }} >
                <Grid
                    container
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                > 
                    <GradientStyle sx={{ mb: 3, width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: { xs: 2, sm: 10 }, paddingX: { xs: 2, sm: 10 } }}>
                        <Stack gap={2} sx={{ mb: 3, paddingX: 2 }} alignItems='start'>
                            <Typography variant={'h5'} sx={{ mb: 1 }}>Marketplace</Typography>
                            <RouterLink to='/' color='gray'>Home</RouterLink>
                            <RouterLink to='/collectionsv2' color='gray'>Collections</RouterLink>
                            <RouterLink to='/createcolletionv2' color='gray'>Create Collection</RouterLink> 
                        </Stack>

                        <Stack gap={2} sx={{ mb: 3, paddingX: 2 }} alignItems='start'>
                            <Typography variant={'h5'} sx={{ mb: 1 }}>Brand Relations</Typography> 
                            <RouterLink to='/corporate/about' color='gray'>About Us</RouterLink>  
                            <RouterLink to='/corporate/partners' color='gray'>Partners</RouterLink>
                        </Stack>
                        <Stack gap={2} sx={{ mb: 3, paddingX: 2 }} alignItems='start'>
                            <Typography variant={'h5'} sx={{ mb: 1 }}>Resources</Typography>
                            <RouterLink to='/resources/beginners' color='gray'>For Beginners</RouterLink>
                            <RouterLink to='/resources/charities' color='gray'>For Charities</RouterLink>
                            <RouterLink to='/resources/brands' color='gray'>For Brands</RouterLink>
                            <RouterLink to='/resources/help'   color='gray'>Help FAQ</RouterLink>

                        </Stack>
                        <Stack gap={2} sx={{ mb: 3, paddingX: 2 }} alignItems='start'>
                            <Typography variant={'h5'} sx={{ mb: 1 }}>My Profile</Typography>
                            <RouterLink to='/account/leader-board' color='gray'>Leaderboards</RouterLink>
                            <RouterLink to='/account/edit' color='gray'>registe/edit Profile</RouterLink>  
                        </Stack> 
                    </GradientStyle> 
                </Grid> 
            </Box> */}
            <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row', }} paddingX={{ xs: 4, md: 10 }} justifyContent={'space-between'} alignItems={"center"} gap={2}>
                <Typography component='div'>
                    ® 2024. <a href="https://zenolabs.pages.dev" style={{color: themeMode === 'light' ?'black':'white' }} target="_blank"   >Zenobit-Labs</a> All Right Reserved   |
                    <RouterLink to='/resources/privacy' color='gray'> Privacy Policy | </RouterLink>
                    <RouterLink to='/resources/cookie' color='gray'> Cookie Policy</RouterLink> 
                </Typography>
                <Box display={'flex'} gap={2}>
                    <IconfyButton icon='bxl:twitter' href="https://www.x.com/zenobit_labs/"/>
                    <IconfyButton icon='gg:facebook' href="https://www.facebook.com/profile.php?id=100095582763601"/>
                    <IconfyButton icon='bxl:instagram-alt' href="https://www.instagram.com/zenobit_labs/"/>
                    {/* <IconfyButton icon='bxs:camera' href="https://www.instagram.com"/> */}
                </Box>
            </Box>
        </RootStyle>
    );
}
