import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { FormattedMessage } from 'react-intl';
import CloseCircle from '../icons/CloseCircle';
import ReceiptText from '../icons/ReceiptText';
import TickCircle from '../icons/TickCircle';
import HorizontalStepperWithError from '../HorizontalStepperWithError';
import { useNavigate } from "react-router";
import { getBlockExplorerUrl } from '../../utils/blockchain';

interface Props {
  dialogProps: DialogProps;
  error?: Error;
  success?: boolean;
  message?: string;
  steps?: any;
  activestep?: number;
  endinfo?: any;
  title?: string;
}

export function SignMessageDialognew({
  dialogProps,
  error,
  success,
  message,
  steps,
  activestep,
  endinfo,
  title,
}: Props) {
  const { onClose } = dialogProps;
  const navigate = useNavigate();
  const handleClose = () => onClose!({}, 'backdropClick');

    const NftsexeplorClick = () => {
        handleClose();
        navigate("/nftslistpage/"+endinfo?.mchainid+"&"+endinfo?.mcontractAddress, { replace: true });       
    } 
    const MyNftsClick = () => {
        handleClose();
        navigate("/account/profile/"+endinfo?.account+"&mynfts", { replace: true });       
    }
    const ColletionsexeplorClick = () => {
        handleClose();
        navigate("/collectionsv2", { replace: true });       
    }

   const MyColletionsClick = () => {
       handleClose();
      navigate("/account/profile/"+endinfo?.account+"&mycolletions", { replace: true });       
   }
  
    const nftdetailClick = () => {
        handleClose();
        navigate("/assets/"+endinfo?.mchainid+"&"+endinfo?.mcontractAddress+"&"+endinfo?.tokenid, { replace: true });       
    }

  const renderSteps = () => {
    if (steps !== undefined) {
      return (
        <Stack spacing={2} justifyContent="center" alignItems="center">
         <HorizontalStepperWithError  steps={steps} activeStep={activestep}  iserror={error!== undefined} isSuccess={success}     />
        </Stack>
      );
    }
  }
  const renderDialogActions = () => {
    if (endinfo !== undefined) {
      return (
        <DialogActions> 
        <Button variant="contained"  onClick={handleClose}>Close</Button>
        </DialogActions>
        );
      }
   }
  const renderEndinfo = () => {
    if (endinfo !== undefined&&endinfo?.mchainid) {
      return (
        <Stack spacing={2} justifyContent="center" alignItems="center">
             <Divider sx={{  mt: 2, mb: 2  }} />
             <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    You can go:
                    </ListSubheader>
                }
                >
                {endinfo?.mchainid&&endinfo?.hash == undefined&&( 
                <ListItemButton onClick={() => ColletionsexeplorClick()} >
                    <ListItemIcon>
                    <SendIcon  color="success"  />
                    </ListItemIcon>
                    <ListItemText primary="Collections Explorer" />
                </ListItemButton>)} 
                {endinfo?.mchainid&&endinfo?.mcontractAddress&&endinfo?.hash == undefined&&( 
                <ListItemButton onClick={() => NftsexeplorClick()} >
                    <ListItemIcon>
                    <SendIcon  color="success"  />
                    </ListItemIcon>
                    <ListItemText primary="NFTS Explorer" />
                </ListItemButton>)} 
                {endinfo?.account&&endinfo?.hash == undefined&&(
                <ListItemButton onClick={() => MyNftsClick()} >
                    <ListItemIcon>
                    <SendIcon color="success"  />
                    </ListItemIcon>
                    <ListItemText primary="MyNFTS" />
                </ListItemButton>)}   
                {endinfo?.account&&endinfo?.hash == undefined&&(
                <ListItemButton onClick={() => MyColletionsClick()} >
                    <ListItemIcon>
                    <SendIcon color="success"  />
                    </ListItemIcon>
                    <ListItemText primary="MyCollections" />
                </ListItemButton>)} 
                {endinfo?.mchainid&&endinfo?.mcontractAddress&&endinfo?.tokenid&&endinfo?.tokenname&&endinfo?.hash == undefined&&( 
                <ListItemButton onClick={() => nftdetailClick()} >
                    <ListItemIcon>
                    <SendIcon color="success"  />
                    </ListItemIcon>
                    <ListItemText primary={`Asset detail for ${endinfo?.tokenname}`}   />
                </ListItemButton>)} 

                {endinfo?.mchainid&&endinfo?.account&&endinfo?.hash !== undefined && (
                  <ListItemButton  color="primary"
                  href={`${getBlockExplorerUrl(endinfo?.mchainid)}/tx/${endinfo?.hash}`}
                  target="_blank">
                    <ListItemIcon>
                    <SendIcon color="success"  />
                    </ListItemIcon>
                    <ListItemText primary="View transaction"  />
                  </ListItemButton> 
                    )}
             </List>  
      </Stack>
      );
    }
  }
  const renderContent = () => {
    if (error !== undefined) {
      return (
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <CloseCircle color="error" fontSize="large" />
          <Box>
            <Typography align="center" variant="h5">
              <FormattedMessage id="error" defaultMessage="Error" />
            </Typography>
            <Typography align="center" variant="body1" color="textSecondary">
              {error?.message}
            </Typography>
          </Box>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={2} justifyContent="center" alignItems="center">
          {success ? (
            <TickCircle color="success" fontSize="large" />
          ) : ( 
            <>
            <CircularProgress /> 
            <Box>
            <Typography align="center" variant="h5">
             Don't close the window, Please!
            </Typography>
            </Box>
            </>
          )}
          <Box>
            <Typography align="center" variant="h5">
              {message}
            </Typography>
            <Typography align="center" variant="body1" color="textSecondary">
            Note: Sign the message on your wallet (if necessary)
            </Typography>
          </Box> 
        </Stack>
      );
    }
  };

  return (
    <Dialog {...dialogProps}  onClose={() => {
        // handleClose()
     } }>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          alignContent="center"
        >
          <ReceiptText />
          <Typography variant="inherit">
           {title}
          </Typography>
        </Stack>
        {onClose&&endinfo !== undefined && (
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <Divider sx={{  mt: 2, mb: 2  }} />
      <DialogContent>
       {renderSteps()}
       <Divider  sx={{  mt: 2, mb: 2  }} />
       {renderContent()}  
       {renderEndinfo()} 
      </DialogContent>
       {renderDialogActions()}
    </Dialog>
  );
}

export default SignMessageDialognew;
