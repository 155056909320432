export const MenuRoutes = [
    {
        title:'Home',
        path:'/'
    }, {
        title:'Market(ERC1155)',
        path:'/collectionsv2'
    }, 
    {
        title:'Ecosystem',
        path:'/ecosystem',
        elements:[
            // {title:'ZenoPASS',path:'/membershipspagev2'},
            {title:'ZenoDigiArt (ERC721)',path:'https://zenodigiart.pages.dev/#/collectionsv2', outLink:true},
            {title:'More...',path:'/comming'} 
        ]
    }, 
    {
        title:'Studio',
        path:'/studio',
        elements:[
            {title:'ZenoPASS',path:'/membershipspagev2'},
            {title:'My ZenoList',path:'/mywhitelistspagev2'},
            {title:'Create Collection',path:'/createcolletionv2'},
            {title:'Create NFT',path:'/createnftv2'}, 
        ]
    }, 
    {
        title:'About Us',
        path:'https://zenolabs.pages.dev'
    }
]

// ZenoDigiArt (721) ZenoNexus (1155) ZenoGiving (1155) ZenoMedia (1155)
// ZenoDigiArt (721) ZenoNexus (1155) - (Note, Ticket, Connect) ZenoGiving (1155) - (Charity, Campaign) ZenoMedia (1155)
// ZenoBook (1155)

// zenodigiart (721) zenonexus (1155) zenogiving (1155) zenomedia (1155)
// zenodigiart (721) zenonexus (1155) - (note, ticket, connect) zenogiving (1155) - (charity, campaign) zenomedia (1155)
// zenobook (1155)
